import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface GateState {
  actualGate: number,
  successCounter: number,
  failedCounter: number
}

const initialState = () => ({
  actualGate: 0,
  successCounter: 0,
  failedCounter: 0
})

const timeState: Module<GateState, RootState> = {
  namespaced: true,
  state: initialState(),

  getters: {
    getGateState: (moduleState: GateState) => moduleState,
    getSuccessCounter: (moduleState: GateState) => moduleState.successCounter
  },

  mutations: {
    RESET: (moduleState: GateState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: GateState, newState: GateState) => {

      moduleState.actualGate = newState.actualGate
      moduleState.successCounter = newState.successCounter
      moduleState.failedCounter = newState.failedCounter

    }
  }
}

export default timeState
