import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface SplitTime {
  text: string,
  color: string
}

export interface SplitTimeState {
  splitTimes: SplitTime[],
  actualTime: string,
  noSplitTimes: boolean
}

const initialState = () => ({
  splitTimes: [],
  actualTime: '',
  noSplitTimes: false
})

const splitTimeState: Module<SplitTimeState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getSplitTimesState: (moduleState: SplitTimeState) => moduleState,
    getSplitTimes: (moduleState: SplitTimeState) => moduleState.splitTimes,
    getActualTime: (moduleState: SplitTimeState) => moduleState.actualTime,
    getNoSplitTimes: (moduleState: SplitTimeState) => moduleState.noSplitTimes
  },

  mutations: {
    RESET: (moduleState: SplitTimeState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: SplitTimeState, newState: SplitTimeState) => {

      moduleState.splitTimes = newState.splitTimes

    },
    ADD_SPLIT_TIME: (moduleState: SplitTimeState, splitTime: SplitTime) => {

      moduleState.splitTimes.push(splitTime)

    },
    SET_ACTUAL_TIME: (moduleState: SplitTimeState, actualTime: string) => {

      moduleState.actualTime = actualTime

    },
    SET_NO_SPLIT_TIMES: (moduleState: SplitTimeState, noSplitTimes: boolean) => {

      moduleState.noSplitTimes = noSplitTimes

    }
  }
}

export default splitTimeState
