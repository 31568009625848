import {
  MobileDetector,
  requestManager
} from '@powerplay/core-minigames'
import type { App } from 'vue'

export const setRatio = {
  install: (app: App): void => {

    app.config.globalProperties.$setRatio = (): void => {

      // tablet nastavujeme podla sirky, mobil podla vysky
      let ratio: number

      /* isTablet */
      if (window.innerWidth / window.innerHeight <= 1.6) {

        ratio = window.innerWidth / 1920
        // this.tablet = MobileDetector.isMobile()

      } else {

        ratio = window.innerHeight / 1080

      }

      // dame na 2 desatinne miesta
      ratio = Math.ceil(ratio * 100) / 100

      // aby sme nezoomovali nad 1
      if (ratio > 1) ratio = 1

      const viewport: HTMLElement | null = document.getElementById('Viewport')
      if (viewport) {

        // pri novom nastaveni flutteru (2 webview) musime davat inak viewport a ine veci dat
        if (requestManager.appRedirect) {

          const scales = 'initial-scale=1,maximum-scale=1'
          viewport.setAttribute(
            'content',
            `width=device-width,${scales},user-scalable=no,viewport-fit=cover`
          )

        } else {

          // Fix problemu na localhoste so scaleom
          if (Number(import.meta.env.VITE_APP_LOCAL) === 1) ratio = 1

          const initialScale = `initial-scale=${ratio}`
          const widthHeight = `width=${window.innerWidth}, height=${window.innerHeight}`

          viewport.setAttribute(
            'content',
            `viewport-fit=cover, ${initialScale}, user-scalable=no, ${widthHeight}`
          )

        }

      }

      // kvoli specialnemu zobrazovaniu cez samostante webview vo flutteri

      if (requestManager.appRedirect) {

        const liveBody: HTMLElement | null = document.getElementById('live-body')
        if (liveBody) {

          liveBody.style.width = '100vw'
          liveBody.style.height = '100vh'

        }
        const liveLayout: HTMLElement | null = document.getElementById('live-layout')
        if (liveLayout) {

          liveLayout.style.width = '100vw'
          liveLayout.style.height = '100vh'
          if (MobileDetector.isMobile()) liveLayout.style.position = 'fixed'

        }

      }

      if (MobileDetector.isMobile() && !MobileDetector.isTablet()) {

        const gw = document.getElementById('game-wrapper') as HTMLElement
        if (gw === null) return
        const sal = Number(getComputedStyle(gw).getPropertyValue('--safe-area-inset-left').replace('px', '')) || 0
        const sar = Number(getComputedStyle(gw).getPropertyValue('--safe-area-inset-right').replace('px', '')) || 0
        gw.style.setProperty('--safe-area-inset-left', `${Math.max(sal, 30) }px`)
        gw.style.setProperty('--safe-area-inset-right', `${Math.max(sar, 30) }px`)

      }

    }

  }
}
