import {
  THREE,
  game,
  type CannonNamedBody,
  errorManager,
  modes
} from '@powerplay/core-minigames'
import {
  modelsConfig,
  gameConfig
} from './config'
import { ModelsNames } from './types'

/**
 * Trieda pre kopec
 */
export class Hill {

  /** Ci ide o predlzenu verziu trate alebo nie */
  public isLongTrack = false

  /** Mesh kopca */
  public hillMesh!: THREE.Mesh

  /** Fyzikalne body kopca */
  public hillBodies: CannonNamedBody[] = []

  /** Nazov meshu plotu */
  public FENCE_NAME = 'SafetyNet'

  /** Mesh plotu */
  public fenceMesh!: THREE.Mesh

  /** Fyzikalne body plotu */
  public fenceBody!: CannonNamedBody

  /** Pole fyzikalnych meshov */
  public PHYSICS_MESHES = [this.FENCE_NAME]

  /**
   * Nastavenie dlzky trate
   */
  public setUpTrackLength(): void {

    // predlzena trat bude v DL a boss sutazi
    this.isLongTrack = modes.isBossCompetition() || modes.isDailyLeague()

  }

  /**
   * Vytvorenie kopca
   */
  public create(): void {

    console.log('VYTVARAM HILL....')
    this.createHillPhysics()
    this.createFencePhysics()
    // this.createFinishGatePhysics()

    console.log('HILL vytvoreny....')

    game.physics.setWorldConfig({
      gravitation: gameConfig.gravitation
    })

  }

  /**
   * Vytvorenie fyziky kopca
   */
  private createHillPhysics(): void {

    const meshHillName = modelsConfig[
      this.isLongTrack ? ModelsNames.hillLong : ModelsNames.hill
    ]?.mainMeshNames?.[0]
    if (!meshHillName) {

      throw new Error(errorManager.showBox('Mesh name for hill was not defined'))

    }

    this.hillMesh = game.getMesh(meshHillName)
    this.hillMesh.visible = false

    const material: THREE.MeshBasicMaterial = this.hillMesh.material as THREE.MeshBasicMaterial
    material.vertexColors = true

    const physicsTrackParts = this.isLongTrack ? 40 : 20

    for (let i = 1; i <= physicsTrackParts; i++) {

      let computedTrack
      if (i < 10) {

        computedTrack = `0${i}`

      } else {

        computedTrack = i

      }
      const physicsName = `Physics_Track_${computedTrack}`
      const physicsMesh = game.getMesh(physicsName)
      physicsMesh.material = material
      physicsMesh.visible = false
      const hillBody = game.physics.addBodyFromMesh(physicsName, physicsMesh, 0)
      this.hillBodies.push(hillBody)

    }

  }

  /**
   * Vytvorenie fyziky plotu
   */
  private createFencePhysics(): void {

    const name = this.FENCE_NAME

    this.fenceMesh = game.getMesh(name)
    this.fenceBody = game.physics.addBodyFromMesh(name, this.fenceMesh, 0)

  }

}

export const hill = new Hill()
