<template>
  <mobile-button
    :type="type"
    :style="positionStyle"
    perform-action
    :disabled="disabled"
    @mousedown="performAction"
    @mouseup="endAction"
    @touchstart="performAction"
    @touchend="endAction"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { MobileButton } from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'
import { inputsManager } from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { DisciplinePhases } from '@/app/types'
import type { StartPhaseManager } from '@/app/phases/StartPhaseManager'

export default defineComponent({
  components: {
    MobileButton
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isStart: 'ActionButtonState/isStart',
      gameSettingsState: 'GameSettingsState/getGameSettingsState'
    }),
    type() {

      return this.isStart ? 'start-downhill' : 'tuck'

    },
    positionStyle() {

      if (this.isStart) return {}
      if (this.gameSettingsState.isLeft) {

        return {
          position: 'absolute',
          left: 0
        }

      }
      return {
        position: 'absolute',
        right: 0
      }

    }
  },
  methods: {
    performAction() {

      if (this.disabled) return

      if (this.type === 'tuck') {

        this.$store.commit('TuckState/SET_STATE', { isTuck: true })

      }

      if (this.type === 'start-downhill') {

        const startPhase = disciplinePhasesManager
          .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
        if (startPhase.isAfterIntro) {

          this.$store.commit('ActionButtonState/SET_TOUCH_START', true)

        }

      }

    },
    endAction() {

      if (this.type === 'tuck') {

        this.$store.commit('TuckState/SET_STATE', { isTuck: false })

      }

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})

</script>

<style>

</style>
