import {
  SectionNames,
  AudioNames
} from '../../types'
import { tutorialDefaultStates } from './TutorialDefaultState'
import store from '@/store'
import {
  audioManager,
  tutorialManager,
  TutorialMessageColors
} from '@powerplay/core-minigames'

/**
 * Informacie pre UI priapdne kontrolne prvky na zmenu UI roznych taskov
 */
export class TutorialUIChange {

  /** Hack lebo nulty task nespusta intro */
  private hack = false

  /** Meno prave beziacej sekcie */
  private actualSectionName: SectionNames | undefined

  /** Samotny stav ktory chceme vo roznych krokoch tutorialu */
  private uiState = {
    [SectionNames.mobileSidePick]: () => {

      console.log('SECTION FIRST')
      if (!this.hack) {

        // document.getElementsByTagName('canvas')[0].classList.add('blur-class')
        store.commit('BlurState/SET_IS_ACTIVE', true)
        this.hack = true

      }
      this.setMobile(true)
      // dame prec loading
      const loadingState = store.getters['LoadingState/getLoadingState']
      const newState = { ...loadingState,
        showLoading: false }
      store.commit('LoadingState/SET_STATE', newState)

    },
    [SectionNames.startSection]: () => {

      this.setMobile(false)
      this.setMessage(true, 'tutorialText1-1')
      this.setAnne(true)
      // dame prec loading (pre web)
      const loadingState = store.getters['LoadingState/getLoadingState']
      const newState = { ...loadingState,
        showLoading: false }
      store.commit('LoadingState/SET_STATE', newState)

      audioManager.play(AudioNames.commentIntro)

    },
    [SectionNames.startSectionSecond]: () => {

      this.setMessage(true, 'tutorialText1-2', undefined, true)
      this.setAnne(true, false)
      store.commit('TutorialState/SET_SHOW_BUTTON_START', true)

    },
    [SectionNames.startSectionThird]: () => {

      // zmenime store - podla storeu sa zmeny UI
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.startSectionFourth]: () => {

      this.setMessage(true, 'tutorialText1-3')
      store.commit('TutorialState/SET_SHOW_BUTTONS_MOVEMENT', true)
      // this.setAnne(true)

    },
    [SectionNames.startSectionFifth]: () => {

      this.setMessage(false, '')
      this.setAnne(false)
      this.resetTypeWrite()
      // zmenime store - podla storeu sa zmeny UI

    },
    [SectionNames.startSectionSixth]: () => {

      // zmenime store - podla storeu sa zmeny UI

    },
    [SectionNames.startSectionSeventh]: () => {

      // zmenime store - podla storeu sa zmeny UI

    }
  }

  /**
   * Zena UI podla aktualnej sekcie
   * @param sectionName - Meno aktualnej sekcie
   */
  private changeUi(sectionName: SectionNames): void {

    this.uiState[sectionName]()

  }

  /**
   * Inicializovanie
   */
  public init(): void {

    // Ak bude treba.

  }

  /**
   * Hlavna logika ui zmeny
   */
  private tutorialUILogic(): void {

    const sectionName = tutorialManager.getActualSectionName() as SectionNames
    if (sectionName && sectionName !== this.actualSectionName) {

      this.changeUi(sectionName)
      this.actualSectionName = sectionName

    }

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    tutorialDefaultStates.update()
    this.tutorialUILogic()

  }

  public setMobile(show: boolean): this {

    store.commit('TutorialState/SET_MOBILE', {
      show
    })
    return this

  }

  /**
   * Resetnutie typewrite
   */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    store.commit('TutorialCoreState/SET_TYPE_WRITER', true)

  }

  public setAnne(
    showAnne: boolean,
    isRight = false
  ): this {

    store.commit('TutorialState/SET_ANNE', {
      showAnne,
      isRight
    })
    return this

  }

  public setMessage(
    showMessage: boolean,
    message = '',
    color = TutorialMessageColors.blank,
    offset = false
  ): this {

    this.resetTypeWrite()
    store.commit('TutorialState/SET_MESSAGE', { showMessage,
      message,
      color,
      offset })
    return this

  }

}

export const tutorialUIChange = new TutorialUIChange()
