<template>
  <split-times
    v-if="showSplitTimes"
    class="split-times"
    :split-times="splitTimes"
    :penalty="penalty"
  />
</template>

<script lang="ts">

import {
  SplitTimes,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'
import { modes } from '@powerplay/core-minigames'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MiddletimesDownhill',
  components: {
    SplitTimes
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
      splitTimes: 'SplitTimeState/getSplitTimes',
      timeState: 'TimeState/getTimeState',
      actualTime: 'SplitTimeState/getActualTime'
    }),
    showSplitTimes() {

      return !modes.isTutorial()

    },
    penalty() {

      // eslint-disable-next-line
            // @ts-ignore
      return this.timeState.penaltySeconds > 0 ?
      // eslint-disable-next-line
                // @ts-ignore
        String(this.timeState.penaltySeconds) :
        ''

    }
  },
  watch: {
    actualTime(newVal) {

      console.log('Realny medzicas', newVal)

    }
  }
})

</script>

<style scoped lang="less">
.split-times {
  position: absolute;
  transform-origin: top left;
  left: 1%;
  top: 17%;
}
</style>
