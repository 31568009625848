import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface MovementState {
  positionX: number
}

const initialState = () => ({
  positionX: 0
})

const movementState: Module<MovementState, RootState> = {
  state: initialState(),
  getters: {
    getDirectionsState: (moduleState: MovementState) => moduleState,
    getPositionX: (moduleState: MovementState) => moduleState.positionX
  },
  mutations: {
    RESET: (moduleState: MovementState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: MovementState, newState: MovementState) => {

      moduleState.positionX = newState.positionX

    },
    SET_POSITION_X: (moduleState: MovementState, positionX: number) => {

      moduleState.positionX = positionX

    }
  },
  namespaced: true
}

export default movementState
