import {
  MainCore,
  game,
  timeManager,
  CameraTypes,
  CustomEvents,
  THREE,
  TimesTypes,
  AppWSM2021DifficultyTypes,
  AudioManager,
  CameraStates,
  requestManager,
  corePhasesManager,
  settings,
  SettingsTypes,
  gameStats,
  fpsManager,
  cameraManager,
  appWSM2021Config,
  modes,
  type LoaderDataTypes,
  GatesBatchingGenerator,
  type MaterialDataObject,
  playersManager,
  CorePhases
} from '@powerplay/core-minigames'
import {
  audioConfig,
  batchingConfig,
  modelsConfig,
  texturesConfig,
  debugConfig,
  cameraConfig,
  gameConfig,
  batchingConfigLongTrack,
  translatesReplacements
} from './config'
import {
  type SpecialDataFromInit,
  TexturesNames,
  MaterialsNames,
  DisciplinePhases,
  ModelsNames,
  type AssetsConfigs,
  AssetsConfigsNames,
  type AssetsConfigsNamesTypes
} from './types'
import store from '@/store'
import { hill } from './Hill'
import { player } from './player'
import { gatesManager } from './GatesManager'
import { inputsManager } from './InputsManager'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import { appWSM2021AllDifficultiesConfig } from './config/appWSM2021AllDifficultiesConfig'
import { checkpointManager } from './modes/training/CheckpointsManager'
import { materialsConfig } from './config/materialsConfig'
import { trainingTasks } from './modes/training/TrainingTasks'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { pathAssets } from '@/globals/globalvariables'
import type { StartPhaseManager } from './phases/StartPhaseManager'
import { ParticleEffects } from './ParticleEffects'
import * as Sentry from '@sentry/vue'

/**
 * Hlavna trieda pre disciplinu
 */
export class Main {

  /** Hlavna trieda z CORE */
  private mainCore!: MainCore

  // Aktualna pozicia kamery
  private actualCameraPosition = new THREE.Vector3()

  // Aktualna rotacia kamery
  private actualCameraQuaternion = new THREE.Quaternion()

  /** Pause prveho tutorialu */
  private pausedFirstTutorial = false

  /** Partikle Effect */
  private particleEffects!: ParticleEffects

  /** Upravene konfigy */
  private editedConfigs: AssetsConfigs = {
    [AssetsConfigsNames.textures]: {},
    [AssetsConfigsNames.models]: {},
    [AssetsConfigsNames.materials]: {}
  }

  /** Split data z init requestu */
  private splitData?: number[]

  /**
   * Konstruktor
   */
  public constructor() {

    this.addListeners()
    // pripravenie konfigov pre WSM 2021 - musime kontrolvat takto kvoli typescriptu
    if (modes.isAppWSM2021()) {

      appWSM2021Config.data = appWSM2021AllDifficultiesConfig[
        modes.mode as unknown as AppWSM2021DifficultyTypes // small TS hack :)
      ]

    }

    if (modes.isTutorial()) {

      // nastavime loading pre tutorial
      store.commit('LoadingState/SET_STATE', {
        tutorial: {
          isEnabled: true,
          text: 'disciplineName1'
        }
      })

    }

    /*
     * Nastavenie players konfigov, aby sa dobre zoradovali a mali dobre empty vysledky
     * nemusime nic nastavovat, lebo berieme default hodnoty z konfigu
     * this.setPlayersConfig()
     */

    // nastavime penalizaciu
    timeManager.setPenaltySecondsForOne(gameConfig.penaltySeconds)

    // spustime CORE veci a pokial vsetko je v pohode, pusti sa INIT metoda
    this.mainCore = new MainCore(
      {
        meshesCastShadows: [], // toto bude naplnene az pri inite
        materials: {},
        callbacks: {
          inputs: {
            callbackLeft: inputsManager.onKeyLeft,
            callbackRight: inputsManager.onKeyRight,
            callbackUp: inputsManager.onKeyUp,
            callbackDown: inputsManager.onKeyDown,
            callbackAction: inputsManager.onKeyAction,
            callbackExit: inputsManager.onKeyExit,
            callbackPrepareVideo: inputsManager.onKeyPrepareVideo
          },
          setSpecialDataFromInitRequest: this.setSpecialDataFromInitRequest,
          createAssets: this.createAssets,
          beforeGameStart: this.beforeGameStart,
          updateBeforePhysics: this.updateBeforePhysics,
          updateAfterPhysics: this.updateAfterPhysics,
          updateAnimations: this.updateAnimations
        },
        batching: new Map(),
        debugConfig,
        numberOfAttempts: gameConfig.numberOfAttempts
      },
      translatesReplacements,
      undefined
    )

    this.initialSetup()

  }

  /**
   * Rozhodneme a vyberieme spravnu trat podla dlzky (dlha BS a DL, kratka vsade inde)
   */
  private selectTrackByLength(): void {

    hill.setUpTrackLength()

    // musime vyhodit z loadingu nejake modely
    const modelsNotToLoad = hill.isLongTrack ?
      [ModelsNames.hill, ModelsNames.gates] :
      [ModelsNames.hillLong, ModelsNames.gatesLong]

    this.setEditedConfig(modelsConfig, AssetsConfigsNames.models, modelsNotToLoad)

    // musime vyhodit z loadingu nejake textury
    const texturesNotToLoad = hill.isLongTrack ?
      [TexturesNames.lightmapHill] :
      [TexturesNames.lightmapHillLong]

    this.setEditedConfig(texturesConfig, AssetsConfigsNames.textures, texturesNotToLoad)

    this.setEditedConfigMaterials()

  }

  /**
   * Nastavenie noveho konfigu podla parametrov, ktory nahradi stary config (resp default)
   * @param config - Stary konfig
   * @param configName - Nazov konfigu
   * @param assetsNotToLoad - Mena assetov, ktore sa nemaju loadovat
   */
  private setEditedConfig(
    config: LoaderDataTypes,
    configName: AssetsConfigsNames,
    assetsNotToLoad: AssetsConfigsNamesTypes[]
  ): void {

    if (!config) return

    for (const [key, value] of Object.entries(config)) {

      if (!assetsNotToLoad.includes(key as AssetsConfigsNamesTypes)) {

        this.editedConfigs[configName][key] = value

      }

    }

  }

  /**
   * Nastavenie noveho konfigu materialovpodla parametrov, ktory nahradi stary/default config
   */
  private setEditedConfigMaterials(): void {

    if (!materialsConfig) return

    for (const [key, value] of Object.entries(materialsConfig)) {

      this.editedConfigs[AssetsConfigsNames.materials][key] = value

      // ak ide o dlhu trat, tak menime lightmapy
      if (value.lightmap === TexturesNames.lightmapHill && hill.isLongTrack) {

        const mat = this.editedConfigs[AssetsConfigsNames.materials] as MaterialDataObject
        mat[key].lightmap = TexturesNames.lightmapHillLong

      }

    }

  }

  /**
   * Metoda na overenie a zobrazenie FPS
   */
  private checkFpsRequest(): void {

    if (store.getters['FpsLookerState/getFpsStarted']) {

      const settingsQuality = settings.getSetting(SettingsTypes.quality)
      const fpsData = {
        averageFps: fpsManager.getAverageFpsByQuality(settingsQuality),
        actualFps: fpsManager.getActualFpsByQuality(settingsQuality),
        actualAverageFps: fpsManager.getActualAverageFps()
      }

      store.commit('FpsLookerState/SET_FPS_STATE', fpsData)

    }

  }

  /**
   * Pridanie listenerov
   */
  private addListeners() {

    window.addEventListener(CustomEvents.readyForDisciplineInit, this.init)
    window.addEventListener(CustomEvents.loadingProgress, this.loadingProgress)

  }

  /**
   * Pociatocny setup
   */
  private initialSetup() {

    const localEnv = Number(import.meta.env.VITE_APP_LOCAL) === 1
    this.mainCore.setIsLocalEnv(localEnv)
    game.setIsLocalEnv(localEnv)

    // lokalne si davame ID discipliny, aby sme nemuseli menit v GET parametroch stale
    if (localEnv) requestManager.disciplineID = 1

    // nastavenie verzie zvukov - TODO: dat mozno niekam inam, resp cez konfig?
    AudioManager.PATH_ASSETS = pathAssets
    disciplinePhasesManager.create()

    /*
     * listener na zistenie appky, ze sme v background mode a mame dat pauzu, aby sme setrili
     * prostriedky a aby nehrali zvuky
     */
    window.addEventListener(CustomEvents.toggleBackgroundMode, () => {

      store.commit('TutorialState/SET_SETTINGS', true)

    }, false)

  }

  /**
   * Vratenie ignorovanych nazvov textur
   * @returns Pole nazvov ignorovanych textur
   */
  private getIgnoredTexturesNames(): string[] {

    const allRaceTextures = [
      TexturesNames.skierRaceBlackMan,
      TexturesNames.skierRaceBlackWoman,
      TexturesNames.skierRaceMulattoMan,
      TexturesNames.skierRaceMulattoWoman,
      TexturesNames.skierRaceWhiteMan,
      TexturesNames.skierRaceWhiteWoman
    ]

    const usedTextures: string[] = []

    // pridame hraca
    const playerInfo = playersManager.getPlayer()
    usedTextures.push(`${playerInfo.sex}/${TexturesNames.skierRacePrefix}${playerInfo.race}`)

    /*
     * pridame superov, ak su
     * ...
     */

    // vysledok bude rozdiel dvoch poli
    return allRaceTextures.filter(x => !usedTextures.includes(x))

  }

  /**
   * Inicializacia main core
   */
  public init = (): void => {

    console.log('discipline INIT')

    /*
     * treba rozhodnut, ci potrebujeme kratsiu alebo dlhsiu verziu trate
     * DOLEZITE - toto musi ist az po inite, ak tam je posielany DL timestamp pre dlhu trat
     */
    this.selectTrackByLength()

    gatesManager.setUp(this.splitData ?? [])

    this.mainCore.init(
      {
        textures: this.editedConfigs[AssetsConfigsNames.textures] as LoaderDataTypes,
        models: this.editedConfigs[AssetsConfigsNames.models] as LoaderDataTypes,
        audio: audioConfig
      },
      this.editedConfigs[AssetsConfigsNames.materials] as MaterialDataObject,
      (
        this.editedConfigs[AssetsConfigsNames.materials] as MaterialDataObject
      )[MaterialsNames.skier].meshesArray || [],
      hill.isLongTrack ? batchingConfigLongTrack : batchingConfig,
      undefined,
      this.getIgnoredTexturesNames(),
      TexturesNames.skierRacePrefix
    )

    const tweenSettingsForCameraStates = hill.isLongTrack ?
      cameraConfig.tweenSettingsForCameraStatesLong :
      cameraConfig.tweenSettingsForCameraStates

    this.mainCore.setTweenSettingsForStates(tweenSettingsForCameraStates)

    cameraManager.changeBaseRenderSettings(undefined, hill.isLongTrack ? 1500 : 1200)
    trainingTasks.initTraining()
    timeManager.setActive(TimesTypes.total, true)
    // disciplinePhasesManager.phaseManagers[0].preparePhase()

    // UI update
    store.commit('DirectionsState/SET_STATE', {
      show: true
    })

    if (corePhasesManager.firstInstructions) {

      store.commit('TrainingResultsState/SET_TRAIN_AGAIN_DISABLED', true)

    }
    const shinyTexts = [{ name: 'miss',
      active: false }]
    if (modes.isTrainingMode()) {

      shinyTexts.push({ name: 'plus-10-pc',
        active: false })

    }

    store.commit('ShinyTextsState/SET_STATE', { texts: shinyTexts })

  }

  /**
   * Zobrazenie progresu loadingu
   */
  private loadingProgress = (): void => {

    gameStats.setNextLoadingPart()

    const loadingState = store.getters['LoadingState/getLoadingState']
    const newState = {
      ...loadingState,
      loadingProgress: gameStats.getLoadingProgress()
    }
    store.commit('LoadingState/SET_STATE', newState)

  }

  /**
   * Nastavenie specialnych dat z init requestu
   * @param data - Specialne data
   */
  private setSpecialDataFromInitRequest = (data: unknown): void => {

    const specialData = data as SpecialDataFromInit

    this.splitData = specialData?.split ?? []

    if (this.splitData.length <= 0) {

      store.commit('SplitTimeState/SET_NO_SPLIT_TIMES', true)

    }

    Sentry.setContext('minigame', { id: requestManager.MINIGAME_START_ID })

  }

  /**
   * Nastavenie assetov
   */
  private createAssets = (): void => {

    // HILL
    hill.create()

    // HRAC
    let position
    if (gameConfig.skipToFinish.active) position = gameConfig.skipToFinish.position
    player.create(position)

    // toto musime nastavit az potom, co nastavime player.startPosition
    disciplinePhasesManager.setDistanceToEnd()

    // BRANKY
    gatesManager.create()

    // CHECKPOINTS
    checkpointManager.create()

    // debug
    this.setUpDebug()

    // Partikle
    this.particleEffects = new ParticleEffects()

  }

  /**
   * puts singletons into window object
   */
  private setUpDebug(): void {

    if (!Number(import.meta.env.VITE_APP_LOCAL)) return

    const debug = {
      gatesManager,
      hill,
      inputsManager,
      player,
      disciplinePhasesManager,
      setVisibityHUD: (visible: boolean) => {

        store.commit('DebugState/SET_HUD_VISIBILITY', visible)

      },
      pauseGame: () => {

        if (game.paused) {

          game.resumeGame()

        } else {

          game.pauseGame()

        }

      },
      GatesBatchingGenerator,
      THREE,
      generateGates: () => {

        const positionsName = hill.isLongTrack ?
          ModelsNames.gatesPositionsLong :
          ModelsNames.gatesPositions
        GatesBatchingGenerator.generateDownhill(
          'Branka0L',
          'Branka0R',
          game.scene.getObjectByName(positionsName) ?? new THREE.Object3D(),
          new THREE.Vector3(
            gameConfig.startPosition.x,
            gameConfig.startPosition.y,
            gameConfig.startPosition.z
          )
        )

      }
    };

    // eslint-disable-next-line
        (window as any).debug = debug

  }

  /**
   * Nastavenie alebo spustenie veci pred startom hry
   */
  private beforeGameStart = (): void => {

    // nastavime pocuvanie na zaciatok disciplinovej fazy z CORE
    window.addEventListener(
      CustomEvents.startDisciplinePhase,
      disciplinePhasesManager.setStartPhase
    )

    store.commit('GameSettingsState/SET_STATE', {
      graphicsSettings: settings.getSetting(SettingsTypes.quality),
      graphicsAuto: settings.getSetting(SettingsTypes.qualityAuto) === 1,
      volume: settings.getSetting(SettingsTypes.sounds) === 1,
      isLeft: settings.getSetting(SettingsTypes.isLeft) === 1,
      controlsType: settings.getSetting(SettingsTypes.controlsType)
    })

  }

  /**
   * Spustenie veci v update pred vykonanim fyziky
   */
  private updateBeforePhysics = (): void => {

    player.updateBeforePhysics()
    disciplinePhasesManager.update()
    this.checkFpsRequest()

    gatesManager.update(gatesManager.needsUpdate.triggered)

  }

  /**
   * Spustenie veci v update po vykonani fyziky
   */
  private updateAfterPhysics = (delta: number): void => {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return

    this.particleEffects.update()

    if (requestManager.isFirstTrainingTutorial() && !this.pausedFirstTutorial) {

      this.pausedFirstTutorial = true
      console.log(requestManager.TUTORIAL_ID)
      game.pauseGame()
      store.commit('TrainingState/SET_FIRST_TUTORIAL', true)
      return

    }

    player.updateAfterPhysics(hill.hillMesh)

    this.actualCameraPosition.copy(player.getPosition())
    this.actualCameraQuaternion.copy(player.getQuaternion())

    cameraManager.move(
      this.actualCameraPosition,
      this.actualCameraQuaternion,
      delta,
      [hill.hillMesh],
      cameraConfig.distanceFromGround,
      cameraManager.isThisCameraState(CameraStates.disciplineOutro)
    )

    store.commit('TimeState/SET_STATE', {
      timeWithPenalty: timeManager.getGameTimeWithPenaltyInFormat(2),
      time: timeManager.getGameTimeWithPenaltyInSeconds(),
      penaltySeconds: timeManager.getPenaltyInfo().seconds
    })

    const startState = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
    if (modes.isTutorial() && startState.shouldTutorialUpdate) {

      tutorialFlow.update()

    }

  }

  /**
   * Spustenie vykonavania vsetkych animacii
   * @param delta - Delta
   */
  private updateAnimations = (delta: number): void => {

    player.updateAnimations(delta)
    gatesManager.updateAnimations(delta)

  }

  /**
   * Zmena kamery na debug, ak by sme potrebvalo
   */
  public changeCameraToDebug = (): void => {

    cameraManager.setCamera(CameraTypes.debug)

  }

}
