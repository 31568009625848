import store from '@/store'
import { audioManager } from '@powerplay/core-minigames'
import {
  AudioNames,
  type DisciplinePhaseManager
} from '../types'

/**
 * Trieda fazy pre zjazd
 */
export class GamePhaseManager implements DisciplinePhaseManager {

  /** callback na zavolanie po skonceni fazy */
  public callbackEnd: () => unknown

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    console.warn('game phase started')
    // store.commit('InputsState/SET_DISABLED', false)
    this.showMobileButtons()
    audioManager.play(AudioNames.voiceStart)

  }

  public showMobileButtons(): void {

    store.commit('ActionButtonState/SET_SHOW_MOVEMENT_CONTROL', true)
    store.commit('ActionButtonState/SET_START_BUTTON', false)
    store.commit('InputsState/SET_DISABLED', true)

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    // zatial netreba nic

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    console.warn('game phase ended')
    this.callbackEnd()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

}
