<template>
  <div class="nomobile-start-bar">
    <start-efficiency-bar
      v-if="startPhaseState.showBar && !isMobile"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import StartEfficiencyBar from './StartEfficiencyBar.vue'

export default defineComponent({
  name: 'EfficiencyComponent',
  components: {
    StartEfficiencyBar
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      startPhaseState: 'StartPhaseState/getStartPhaseState'
    })
  }
})

</script>

<style lang='less' scoped>
.nomobile-start-bar {

    pointer-events: none;
    position: absolute;
    width: 375px;
    height: 375px;
    right: 0;
    bottom: 0;

}
</style>
