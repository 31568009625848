import {
  RequestLogTypes,
  requestManager,
  errorManager,
  game,
  CustomEvents
} from '@powerplay/core-minigames'
import store from '@/store'
import type { App } from 'vue'

// array na errorove message, aby sa neposielali viackrat
const errorMessages: string[] = []

// eslint-disable-next-line
const createVueErrorMessage = (error: Error, info: any): string => {

  return JSON.stringify({
    error: 'VUE ERROR',
    message: error.message,
    stack: error.stack,
    info: info
  })

}

const createGlobalErrorMessage = (
  message: string | Event,
  source: string | undefined,
  lineno: number | undefined,
  colno: number | undefined,
  error: Error | undefined
): string => {

  return JSON.stringify({
    error: 'JS ERROR',
    message,
    source,
    lineno,
    colno,
    errorSpec: error
  })

}

const createGlobalAsyncError = (
  message: string,
  event: string | Event
): string => {

  return JSON.stringify({
    error: 'ASYNC ERROR',
    message,
    event
  })

}

const includeErrorInArray = (message: string): boolean => {

  if (errorMessages.includes(message)) return true
  errorMessages.push(message)
  return false

}

const sendErrorToServer = (message: string): void => {

  const requestData = {
    error_data: message
  }
  requestManager.sendLogRequest(RequestLogTypes.error, requestData)

}

const createErrorBox = (): void => {

  window.dispatchEvent(new CustomEvent(CustomEvents.errorOccured))
  if (store.getters['LoadingState/getLoadingState'].showLoading) {

    errorManager.setErrorBox(true)

  }
  if (errorManager.getErrorBox()) {

    errorManager.setErrorBox(false)
    store.commit('ErrorState/SET_STATE', {
      errorText: 'Error has occured'
    })
    game.pauseGame()

  }

}

const throwError = (errorMessage: string): void => {

  if (includeErrorInArray(errorMessage)) return
  // sendErrorToServer(errorMessage)
  createErrorBox()

}

export const globalErrorHandler = (app: App<Element>): void => {

  // Production global property config
  if (import.meta.env.VITE_APP_NODE_ENV === 'production') {

    // odchytavanie vue chyb v templateoch
    app.config.errorHandler = (err, _, info) => {

      console.warn(err)
      const error: Error = err as Error
      // vm nedavame, lebo je tam strasne vela dat
      const message = createVueErrorMessage(error, info)
      console.warn('vue-error', message)
      throwError(message)

    }

    // odchytavanie mimo vue chyb, napr. phaser
    window.onerror = (message, source, lineno, colno, error) => {

      const errorMessage = createGlobalErrorMessage(message, source, lineno, colno, error)
      console.warn('non-vue-error', errorMessage)
      throwError(errorMessage)

    }

    window.addEventListener('unhandledrejection', (event) => {

      const errorMessage = createGlobalAsyncError('Async error', event)
      console.warn('async-errors', errorMessage)
      throwError(errorMessage)

    })

  }

}
