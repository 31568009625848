<template>
  <div
    class="training-results layout"
    :class="isMobile() ? 'mobile-overlay' : ''"
  >
    <training-result
      :reward-data="rewardData"
      :training-name="name"
      :training-icon="icon"
      :show-train-again="trainingResultsState.showTrainAgain && otherSituations"
      :disabled-play-again-button="trainingResultsState.isDisabledPlayAgain"
      :high-score="trainingResultsState.bestScore"
      :is-new-high-score="isNewHighScore()"
      @button-click-play-again="onButtonClickPlayAgain()"
      @button-click-continue="onButtonClickContinue()"
    />
    <tutorial-training-rewards
      v-if="showTutorialRewards"
      :subheader="$t('tutorialAnneTableSubheader')"
      :rewards="rewardData.rewards"
      :main-rewards="rewards"
      @on-click-continue="onTutorialRewardsButtonContinue()"
    />
  </div>
</template>

<script lang="ts">

import { mapGetters } from 'vuex'
import {
  WindowAspect,
  TrainingResult,
  TutorialTrainingRewards
} from '@powerplay/core-minigames-ui'
import {
  corePhasesManager,
  requestManager,
  gsap,
  trainingManager,
  game,
  playersManager,
  timeManager,
  modes,
} from '@powerplay/core-minigames'
import type { ResponseInit } from '@powerplay/core-minigames'
import { pathAssets } from '@/globals/globalvariables'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { player } from '@/app/player'
import { gatesManager } from '@/app/GatesManager'
import { checkpointManager } from '@/app/modes/training/CheckpointsManager'
import { gameConfig } from '@/app/config'
import { trainingTasks } from '@/app/modes/training/TrainingTasks'
import { endManager } from '@/app/EndManager'
import type { TrainingResultsState } from '@/store/modules/trainingResultsState'
import { defineComponent } from 'vue'

const createData = () => ({
  stars: 0,

  score: {
    base: 0,
    total: 0,
    bonuses: 0,
  },

  bonus: {
    benefits: 0,
    alchemy: 0,
    subscription: 0,
  },

  subscription: {
    active: 0,
    tp: 0,
  },

  attribute: {
    value_from: 0,
    value_next: 0,
    tp_from: 0,
    tp_new: 0,
    tp_milestone: 0,
  },

  energy: {
    value_new_game: 0,
    available: 0,
  },

  rewards: [],
})
export default defineComponent({
  components: {
    TrainingResult,
    TutorialTrainingRewards
  },
  mixins: [WindowAspect],
  data() {

    return {
      rewardData: createData(),
      gsapes: [] as gsap.core.Tween[],
      pathImages: pathAssets,
      rewards: [
        {
          type: 'TRAINING_POINTS',
          title: this.$t('tutorialRewardsTitle1'),
          text: this.$t('tutorialRewardsText1')
        },
        {
          type: 'experience',
          title: this.$t('tutorialRewardsTitle2'),
          text: this.$t('tutorialRewardsText2')
        },
      ],
      showTutorialRewards: false
    }

  },
  computed: {
    ...mapGetters({
      trainingResultsState: 'TrainingResultsState/getTrainingResultsState',
      gameState: 'GameState/getGameState',
    }),
    firstInstruction() {

      return corePhasesManager.firstInstructions

    },
    isTrainer() {

      return modes.isTrainingWithTrainerMode()

    },
    name() {

      return this.$t(`disciplineName${requestManager.disciplineID}`)

    },
    icon() {

      return `${pathAssets}/ui/table/ICO-DISCIPLINE.png`

    },
    otherSituations(): boolean {

      return (
        !this.isTrainer &&
                !this.firstInstruction &&
                !this.gameState.isPrematureEnd
      )

    },
  },
  watch: {
    trainingResultsState: {
      deep: true,
      handler(value: TrainingResultsState) {

        if (value.showResults && value.dataSet) {

          this.rewardData = createData()
          const { data } = this.trainingResultsState

          const duration = 0.5
          const starsDuration = 0.3 * (data.stars ?? 0)

          let delay = duration + starsDuration + 0.2
          const rewardsDuration = duration / (data.rewards?.length || 1)

          this.rewardData.attribute.tp_milestone = data.attribute.tp_milestone
          this.rewardData.attribute.tp_new = data.attribute.tp_from
          this.rewardData.attribute.value_from = data.attribute.value_from
          this.rewardData.attribute.value_next = data.attribute.value_from + 1
          this.rewardData.bonus = data.bonus

          const gsaper1 = gsap.to(this.rewardData, {
            callbackScope: this,
            duration: duration + starsDuration,
            ease: 'linear',
            stars: data.stars,
          })

          const gsaper2 = gsap.to(this.rewardData.score, {
            callbackScope: this,
            duration,
            delay,
            base: data.score.base,
            total: data.score.total,
            bonuses: data.score.bonuses,
          })
          this.gsapes.push(gsaper1)
          this.gsapes.push(gsaper2)
          delay += duration

          /*
           * gsap.to(this.rewardData.subscription, {
           *     callbackScope: this,
           *     duration,
           *     active: data.subscription.active,
           *     tp: data.subscription.tp
           * })
           */

          const newMilestone =
            data.attribute.tp_milestone_new ?? data.attribute.tp_milestone

          const attributeUp =
            data.attribute.value_from + 1 !== data.attribute.value_next
          const newValue = attributeUp ?
            this.rewardData.attribute.tp_milestone :
            data.attribute.tp_new

          const gsap3 = gsap.to(this.rewardData.attribute, {
            callbackScope: this,
            duration,
            delay,
            tp_new: newValue,
            onComplete: () => {

              if (!attributeUp) return

              this.rewardData.attribute.tp_new = 0
              this.rewardData.attribute.tp_milestone = newMilestone
              this.rewardData.attribute.value_from =
                data.attribute.value_next - 1
              this.rewardData.attribute.value_next = data.attribute.value_next

              gsap.to(this.rewardData.attribute, {
                callbackScope: this,
                duration,
                delay: 0.2,
                tp_new: data.attribute.tp_new,
              })

            },
          })
          this.gsapes.push(gsap3)
          // toto davame, ked je vyssi atribut, lebo potrebujeme to dat neskor dalsie
          if (attributeUp) delay += duration + 0.2

          delay += duration
          this.rewardData.energy.value_new_game = data.energy.value_new_game
          this.rewardData.energy.available = data.energy.available

          let index = 0

          gsap.timeline({
            duration: rewardsDuration,
            repeat: data.rewards?.length ?? 1,
            delay,
            onRepeat: () => {

              if (data?.rewards[index]) {

                // eslint-disable-next-line
                // @ts-ignore
                this.rewardData.rewards.push(data.rewards[index])

              }
              index += 1

            },
            onComplete: () => {

              if (requestManager.isFirstTrainingTutorial()) {

                this.showTutorialRewards = true

              }

            }
          })

        }

      },
    },
  },
  methods: {
    onButtonClickPlayAgain() {

      console.log('clicked to play again')
      this.$store.commit('TrainingResultsState/SET_STATE_RESULTS', false)

      if (this.gsapes.length > 0) {

        this.gsapes.forEach((gg: gsap.core.Tween) => gg.kill())
        this.gsapes = []

      }
      /*
       * TODO: reset game
       * Create dark overlay
       */
      this.$store.commit('BlackOverlay/SET_OVERLAY', true)

      const { gravitation } = gameConfig
      player.reset()
      gatesManager.reset()
      checkpointManager.resetCheckpoints()
      trainingTasks.reset()
      timeManager.reset()
      game.physics.getPhysicsWorld.gravity.y = gravitation.y

      // Get init data
      requestManager.sendInitRequest((data: ResponseInit) => {

        const dataFromRequest: ResponseInit = data

        // trening
        if (dataFromRequest.trainingData) {

          trainingManager.setUpFromData(dataFromRequest.trainingData)

        }

        trainingTasks.initTraining()

        playersManager.players = dataFromRequest.players

        // Reset vsetkych veci - core

        // TODO: Reset game
        corePhasesManager.reset()

        requestManager.sendUpdateParamsRequest(() => {

          this.$store.dispatch('clearStateAll')
          game.restart()
          endManager.reset()

          disciplinePhasesManager.reset()
          corePhasesManager.startFirstPhase()

          // remove overlay

          this.$store.commit('BlackOverlay/SET_OVERLAY', false)

        })

      }, trainingManager.retry)

    },
    onButtonClickContinue() {

      console.log('clicked to continue')
      this.$store.commit('TrainingResultsState/SET_STATE_RESULTS', false)
      corePhasesManager.setNextPhase()

    },
    isNewHighScore() {

      return trainingManager.isNewHighScore()

    },
    onTutorialRewardsButtonContinue() {

      this.showTutorialRewards = false
      this.$store.commit('WaitingState/SET_STATE', {
        isWaiting: false,
        trainingButtonsDisabled: false
      })

    }
  },
})

</script>

<style lang="less" scoped>
.training-results {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  &.mobile-overlay {
      position: fixed;
  }
}
</style>
