<template>
  <section class="positioner">
    <blue-box
      v-if="speedmeterState.visible"
      :show-border="true"
    >
      {{ calculateSpeedKmH }} km/h
    </blue-box>
  </section>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import { BlueBox } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SpeedMeterComponent',
  components: {
    BlueBox
  },
  computed: {
    ...mapGetters({
      speedmeterState: 'SpeedmeterState/getSpeedmeterState'
    }),
    calculateSpeedKmH() {

      // eslint-disable-next-line
            // @ts-ignore
      return (this.speedmeterState.speed * 3.6).toFixed(2)

    }
  }
})

</script>

<style lang='less' scoped>
.positioner {
  left: 50%;
  position: absolute;
  top: 1rem;

  .blue-box {
    margin-top: 4%;
    text-shadow: 0.6px 0.8px 0.9px rgb(0 0 0 / 71%);
    font-family: Roboto;
    font-size: 48px;
    font-weight: 700;
    font-style: italic;
    line-height: 1.02;
    letter-spacing: normal;
    color: #fff;
  }
}
</style>
