import type { TutorialObjective } from '@powerplay/core-minigames'
import { TutorialMessageColors } from '@powerplay/core-minigames'
import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface TutorialState {
  anne: {
    showAnne: boolean,
    isRight: boolean
  },
  mobile: boolean,
  tutorialMessage: {
    message: string,
    showMessage: boolean,
    color: TutorialMessageColors,
    offset: boolean
  },
  objectives: TutorialObjective[],
  showObjectives: boolean,
  showButtonStart: boolean,
  showButtonsMovement: boolean,
  showBarStart: boolean,
  settings: boolean
}

const initialState = () => ({
  anne: {
    showAnne: false,
    isRight: false
  },
  mobile: false,
  tutorialMessage: {
    message: '',
    showMessage: false,
    color: TutorialMessageColors.blank,
    offset: false
  },
  objectives: [],
  showObjectives: false,
  showButtonStart: false,
  showButtonsMovement: false,
  showBarStart: false,
  settings: false
})

const tutorialState: Module<TutorialState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getTutorialState: (moduleState: TutorialState) => moduleState,
    getTutorialObjectives: (moduleState: TutorialState) => moduleState.objectives,
    getShowTutorialObjectives: (moduleState: TutorialState) => moduleState.showObjectives,
    getSettings: (moduleState: TutorialState) => moduleState.settings
  },

  mutations: {
    RESET: (moduleState: TutorialState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: TutorialState, newState: TutorialState) => {

      moduleState = newState

    },
    SET_MOBILE: (
      moduleState: TutorialState,
      newValue: boolean
    ) => {

      moduleState.mobile = newValue

    },
    SET_SETTINGS: (moduleState: TutorialState, bool: boolean) => {

      moduleState.settings = bool

    },
    SET_ANNE: (
      moduleState: TutorialState,
      newValue: {
        showAnne: false,
        isRight: false
      }
    ) => {

      moduleState.anne = newValue

    },
    SET_ANNE_IS_RIGHT: (moduleState: TutorialState, newValue: boolean) => {

      moduleState.anne.isRight = newValue

    },
    SET_MESSAGE: (
      moduleState: TutorialState,
      newState: {
        message: string,
        showMessage: boolean,
        color: TutorialMessageColors.blank,
        offset: boolean
      }
    ) => {

      moduleState.tutorialMessage = newState

    },
    SET_OBJECTIVES: (moduleState: TutorialState, objectives: TutorialObjective[]) => {

      moduleState.objectives = [...objectives]

    },
    SET_SHOW_BUTTON_START: (moduleState: TutorialState, showButtonStart: boolean) => {

      moduleState.showButtonStart = showButtonStart

    },
    SET_SHOW_BUTTONS_MOVEMENT: (moduleState: TutorialState, showButtonsMovement: boolean) => {

      moduleState.showButtonsMovement = showButtonsMovement

    },
    SET_SHOW_BAR_START: (moduleState: TutorialState, showBarStart: boolean) => {

      moduleState.showBarStart = showBarStart

    },
    SET_SHOW_OBJECTIVES: (moduleState: TutorialState, show: boolean) => {

      moduleState.showObjectives = show

    }
  }
}

export default tutorialState
