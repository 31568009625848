import type { Module } from 'vuex'
import type { RootState } from '../index'
import type { GameplayTableRow } from '../../app/types'

export interface GameplayTableState {
  showTables: boolean,
  showLeftTable: boolean,
  showRightTable: boolean,
  tableData: GameplayTableRow[],
  playerData: GameplayTableRow
}

const initialState = () => ({
  showTables: false,
  showLeftTable: true,
  showRightTable: true,
  tableData: [{
    position: 0,
    country: '',
    countryString: '',
    player: {
      name: '',
      isPlayer: true
    },
    time: '',
    timeDiff: '',
    isBonus: false
  }],
  playerData: {
    position: 0,
    country: '',
    countryString: '',
    player: {
      name: '',
      isPlayer: true
    },
    time: '',
    timeDiff: '',
    isBonus: false
  }
})

const gameplayTableState: Module<GameplayTableState, RootState> = {
  namespaced: true,
  state: initialState(),

  getters: {
    getGameplayTableState: (moduleState: GameplayTableState) => moduleState,
    getTableData: (moduleState: GameplayTableState) => moduleState.tableData,
    getPlayerData: (moduleState: GameplayTableState) => moduleState.playerData
  },

  mutations: {
    RESET: (moduleState: GameplayTableState) => {

      Object.assign(moduleState, initialState())

    },
    SET_TABLE_DATA: (moduleState: GameplayTableState, tableData: GameplayTableRow[]) => {

      moduleState.tableData = tableData

    },
    SET_PLAYER_DATA: (moduleState: GameplayTableState, playerData: GameplayTableRow) => {

      moduleState.playerData = playerData

    },
    SET_TABLES_VISIBILITY: (moduleState: GameplayTableState, newState: GameplayTableState) => {

      if (newState.showTables !== undefined) {

        moduleState.showTables = newState.showTables

      }
      if (newState.showLeftTable !== undefined) {

        moduleState.showLeftTable = newState.showLeftTable

      }
      if (newState.showRightTable !== undefined) {

        moduleState.showRightTable = newState.showRightTable

      }

    }
  }
}

export default gameplayTableState
