import store from '@/store'
import {
  corePhasesManager,
  modes,
  playersManager,
  requestManager,
  timeManager,
  trainingManager,
  gameStats
} from '@powerplay/core-minigames'
import type { TrainingDataFromResultsRequest } from '@powerplay/core-minigames'
import { gatesManager } from './GatesManager'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { tutorialObjectives } from './modes/tutorial/TutorialObjectives'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import type { StartPhaseManager } from './phases/StartPhaseManager'
import { player } from './player'
import {
  DisciplinePhases,
  type SaveResultsDataToSend
} from './types'

/**
 * Trieda pre koniec discipliny
 */
export class EndManager {

  /** ci uz bol result poslany alebo nie */
  private resultSent = false

  /** zaznam najvyssej rychlosti dosiahnutej pocas behu appky */
  public maxSpeedLog = 0

  /** zaznam poctu perfektnych startov pocas behu appky */
  public perfectStartsLog = 0

  /**
   * Poslanie requestu pre konecne logovanie
   */
  public sendLogEnd(): void {

    // ak uz mame nastavene, tak uz viac nenastavujeme
    if (Object.keys(gameStats.getDisciplineDataToLog()).length > 0) return

    const startPhaseManager = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager

    // zaznamename nejake info pre logy
    gameStats.setDisciplineDataToLog({
      time: playersManager.dnf ? 0 : timeManager.getGameTimeWithPenaltyInSeconds(true),
      penalty: timeManager.getPenaltyInfo().seconds,
      dnf: playersManager.dnf,
      outOfBounds: player.outOfBounds,
      split: playersManager.dnf ? [] : gatesManager.splitTimeManager.getAllSplitTimes(),
      qualities: {
        start: startPhaseManager.clickedPower / 100
      },
      playerPosition: playersManager.getPlayerActualPosition(),
      trainingTasks: modes.isTrainingMode() ?
        trainingManager.getTrainingTasks().map(task => task.value) :
        [],
      tutorialData: modes.isTutorial() ? this.getTutorialLogs() : []
    })

    console.log('LOG to send', gameStats.getDisciplineDataToLog())

  }

  /**
   * ziskame pole tutorialovych logov
   *
   * @returns - pole tutorialovych logov
   */
  private getTutorialLogs(): (number | boolean)[] {

    return [
      gatesManager.totalFailedCounter, // missedGates
      1, /** TODO zatial nije viac pokusov */ // attempts
      tutorialFlow.fenceEventsTriggered, // contact
      tutorialObjectives.isAllObjectivesPassed(), // completed
      disciplinePhasesManager.prematureEnded // exited
    ]

  }

  /**
   * Vybratie dat a poslanie do funkcie z core-minigames
   */
  public sendSaveResult(): void {

    // ak uz bol result poslany, neposielame ho znova
    if (this.resultSent) return

    this.resultSent = true

    // TODO TEMP - zatial takto, ked bude hotovy tutorial, tak sa to bude posielat tam
    requestManager.sendTutorialRequest()
    if (modes.isTutorial()) return

    const data: SaveResultsDataToSend = {
      time: playersManager.players[0].resultsArr?.[
        corePhasesManager.disciplineActualAttempt - 1
      ].main || 0,
      penalty_time: timeManager.getPenaltyInfo().seconds,
      split_times: playersManager.dnf ? [] : gatesManager.splitTimeManager.getAllSplitTimes(),
      positions: playersManager.getPlayersPositions(),
      dnf: playersManager.dnf,
      perfectStarts: this.perfectStartsLog,
      maxSpeeds: [Number((this.maxSpeedLog * 3.6).toFixed(2))]
    }

    if (modes.isTrainingMode()) {

      data.trainingResults = trainingManager.getTrainingTasks().map(task => task.value)

    }

    console.log('data to send', data)

    requestManager.sendSaveResultsRequest(
      (dataCallback: TrainingDataFromResultsRequest | unknown) => {

        console.log('saveRequest Successful')

        if (modes.isTrainingMode()) {

          store.commit('TrainingResultsState/SET_STATE_DATA', {
            data: dataCallback,
            bestScore: trainingManager.bestScore
          })

        }

      },
      JSON.stringify(data)
    )

  }

  /**
   * Reset result
   */
  public reset(): void {

    this.resultSent = false
    this.perfectStartsLog = 0
    this.maxSpeedLog = 0

  }

}

export const endManager = new EndManager()
