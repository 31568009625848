<template>
  <section class="positioner">
    <section
      v-if="buttonsVisible && showMovementControl && isControlsTypeAutoTuck"
    >
      <movement-sides
        :disabled="isDisabled"
        :left-style="{transformOrigin: 'bottom left'}"
        :right-style="{transformOrigin: 'bottom right'}"
        @position="changeMovement"
        @end-contact="endContact"
      />
    </section>
    <section
      v-if="buttonsVisible && !(isControlsTypeAutoTuck && showMovementControl)"
      :key="String(pomKey)"
    >
      <section
        v-if="!gameSettingsState.isLeft"
        class="flex justify-between"
      >
        <movement-control
          v-if="showMovementControl && !isControlsTypeAutoTuck"
          :style="`transform: scale(${scaleCoef})`"
          class="left"
          :disabled="isDisabled"
        />
        <section v-show="!showMovementControl" />
        <section
          :style="`transform: scale(${scaleCoef})`"
          class="relative right"
        >
          <action-button
            :is-scaled="false"
            :disabled="isDisabled"
          />
          <start-efficiency-bar
            v-if="startPhaseState.showBar"
            :is-scaled="false"
          />
        </section>
      </section>
      <section
        v-else
        class="flex justify-between"
      >
        <section
          :style="`transform: scale(${scaleCoef})`"
          class="relative left"
        >
          <start-efficiency-bar
            v-if="startPhaseState.showBar"
            :is-scaled="false"
            :is-left="gameSettingsState.isLeft"
          />
          <action-button
            :is-scaled="false"
            :disabled="isDisabled"
          />
        </section>
        <movement-control
          v-if="showMovementControl && !isControlsTypeAutoTuck"
          :disabled="isDisabled"
          :style="`transform: scale(${scaleCoef})`"
          class="right"
        />
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MovementControl from './MovementControl.vue'
import ActionButton from './ActionButton.vue'
import { mapGetters } from 'vuex'
import StartEfficiencyBar from './StartEfficiencyBar.vue'
import {
  MovementSides,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import {
  ControlsTypes,
  inputsManager,
  modes
} from '@powerplay/core-minigames'

export default defineComponent({
  components: {
    MovementControl,
    StartEfficiencyBar,
    ActionButton,
    MovementSides
  },
  mixins: [WindowAspect],
  data() {

    return {
      pomKey: false
    }

  },
  computed: {
    ...mapGetters({
      isDisabled: 'InputsState/getDisabled',
      buttonsVisible: 'InputsState/getIsVisible',
      showMovementControl: 'ActionButtonState/getShowMovementControl',
      startPhaseState: 'StartPhaseState/getStartPhaseState',
      gameSettingsState: 'GameSettingsState/getGameSettingsState'
    }),
    isControlsTypeAutoTuck() {

      if (modes.isTutorial()) return true
      return this.gameSettingsState.controlsType === ControlsTypes.autoTuck ||
                Number(import.meta.env.VITE_APP_AUTOTUCK_FORCED) === 1

    }
  },
  watch: {
    showMovementControl() {

      this.$nextTick(() => {

        this.pomKey = true

      })

    }
  },
  methods: {
    endContact() {

      this.$store.commit('MovementState/SET_POSITION_X', 0)

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    changeMovement(positionX: number) {

      this.$store.commit('MovementState/SET_POSITION_X', positionX)

    }
  }
})
</script>

<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.right {
  transform-origin: bottom right;
}
.left {
  transform-origin: bottom left;
}

</style>
