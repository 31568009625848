import {
  audioManager,
  modes,
  timeManager,
  gsap,
  minigameConfig
} from '@powerplay/core-minigames'
import {
  audioGameConfig,
  gatesConfig
} from './config'
import {
  AudioGroups,
  AudioNames,
  type SplitInfo
} from './types'
import store from '@/store'
import { gatesManager } from './GatesManager'
import { hill } from './Hill'

/**
 * Trieda pre spravu medzicasov
 */
export class SplitTimeManager {

  /** Indexy branok, kde budu medzicasy */
  private splitInfo: SplitInfo[] = []

  /** Pocet medzicasov */
  private splitCount = 0

  /** Aktualny index medzicasu */
  private actualSplitIndex = 0

  private afterSplitTween: gsap.core.Tween | undefined

  /**
   * Konstruktor
   */
  public constructor() {

    let split = gatesConfig.split
    if (hill.isLongTrack) split = gatesConfig.splitLong

    this.splitInfo = split.map((val, i) => ({ splitIndex: i,
      gateIndex: val }))
    this.splitCount = this.splitInfo.length

  }

  /**
   * getter
   * @returns splitCount
   */
  public getSplitCount(): number {

    return this.splitCount

  }

  /**
   * Nastavenie najlepsich medzicasov
   * @param data - Data medzicasov
   */
  public setBestSplit(data: number[]): void {

    this.splitInfo = this.splitInfo.map((val, idx) => {

      if (data[idx]) {

        return { ...val,
          bestTime: data[idx] }

      }

      return val

    })

  }

  /**
   * Skontrolovanie aktualneho medzicasu a jeho zapisanie, ak presiel brankou daneho medzicasu
   * @param gateIndex - Index branky
   * @param actualTime - Aktualny cas v s
   */
  public checkActualSplit(gateIndex: number, actualTime: number): void {

    if (gateIndex === gatesManager.gatesCount - 4) {

      this.manageStateBeforeFinish()

    }

    this.manageStateBeforeSplitTime(gateIndex)

    if (
      this.actualSplitIndex >= this.splitCount ||
            this.splitInfo[this.actualSplitIndex].gateIndex !== gateIndex
    ) return

    const bestTime = this.splitInfo[this.actualSplitIndex].bestTime

    // zaznamenavame medzicas
    this.splitInfo[this.actualSplitIndex].time = actualTime

    const difference = actualTime - (bestTime ?? actualTime)
    const differencePrefix = SplitTimeManager.getDifferencePrefix(difference)
    const differenceText = SplitTimeManager.formatDifferenceTime(difference, differencePrefix)

    this.manageStateOnSplitTime(bestTime ?? minigameConfig.dnfValue)

    this.splitInfo[this.actualSplitIndex].difference = differenceText

    // zapiseme do UI - TODO warn, ked bude UI, tak mozeme vymazat
    if (bestTime) {

      store.commit(
        'SplitTimeState/ADD_SPLIT_TIME',
        { text: differenceText,
          color: SplitTimeManager.getColor(difference) }
      )
      store.commit('SplitTimeState/SET_ACTUAL_TIME', actualTime)

    }

    // presunieme sa na dalsi medzicas
    this.actualSplitIndex++

    // pri treningu nechceme zahrat zvuk
    if (modes.isTrainingMode()) return

    if (differencePrefix === '-') {

      audioManager.play(AudioNames.checkpointFail)

    } else {

      audioManager.play(AudioNames.checkpointGood)

    }

    this.playCommentatorSplitTime(difference)

  }

  /**
   * pustime komentatora split time
   */
  private playCommentatorSplitTime(difference: number): void {

    let audio = AudioNames.commentSplitTimesPlus3

    if (difference <= 0) {

      audio = AudioNames.commentSplitTimesMinus

    } else if (difference <= audioGameConfig.splitTimeDifference.first) {

      audio = AudioNames.commentSplitTimesPlus1

    } else if (difference <= audioGameConfig.splitTimeDifference.second) {

      audio = AudioNames.commentSplitTimesPlus2

    }

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(audio)

  }

  /**
   * Zistenie prefixu pre diff
   * @param difference - Diff
   * @returns Prefix
   */
  public static getDifferencePrefix(difference: number): string {

    let differencePrefix = ''
    if (difference > 0) differencePrefix = '+'
    if (difference < 0) differencePrefix = '-'
    return differencePrefix

  }

  /**
   * Vratenie farby pre medzicasove UI
   * @param difference - Rozdiel casu
   * @returns Farba
   */
  public static getColor(difference: number): string {

    return difference > 0 ? 'red' : 'green'

  }

  /**
   * Naformatovanie diffu casu
   * @param difference - diff
   * @param differencePrefix - prefix pre diff
   * @returns Naformatovany diff time
   */
  public static formatDifferenceTime(difference: number, differencePrefix: string): string {

    const timeInFormat = timeManager.getTimeInFormatFromSeconds(Math.abs(difference))
    return `${differencePrefix}${timeInFormat}`

  }

  /**
   * reset managera
   */
  public reset(): void {

    this.splitCount = 0
    this.actualSplitIndex = 0
    timeManager.reset()

  }

  /**
   * Vratenie vsetkych checkpointov
   * @returns Pole checkpointov
   */
  public getAllSplitTimes(): number[] {

    return this.splitInfo.map((value) => value.time || 0)

  }

  /**
   * zastavenie casu na casemiere a nastavenie tweenu na znovuspustenie
   * @param bestTime - number
   */
  public manageStateOnSplitTime(bestTime: number): void {

    if (modes.isTutorial() || modes.isTrainingMode()) return

    store.commit('TimeState/SET_SHOW_BOOLEANS', {
      v2Expanded: false,
      showDiff: true,
      diffIndex: this.actualSplitIndex,
      isV1: true,
      doTimeUpdate: false,
      bestTime: bestTime,
      isFinish: false
    })

    this.afterSplitTween = gsap.to({}, {
      onComplete: () => {

        store.commit('TimeState/SET_SHOW_BOOLEANS', {
          v2Expanded: false,
          showDiff: false,
          diffIndex: this.actualSplitIndex,
          isV1: true,
          doTimeUpdate: true,
          bestTime: bestTime,
          isFinish: false
        })

      },
      duration: 4
    })

  }

  /**
   * nastavenie odpocitavnia 2 branky pred split time
   * @param gateIndex - number
   */
  public manageStateBeforeSplitTime(gateIndex: number): void {

    if (modes.isTutorial() || modes.isTrainingMode()) return

    if (
      this.actualSplitIndex < this.splitCount &&
            (
              this.splitInfo[this.actualSplitIndex].gateIndex - 2 <= gateIndex &&
                this.splitInfo[this.actualSplitIndex].gateIndex > gateIndex
            )
    ) {

      store.commit('TimeState/SET_SHOW_BOOLEANS', {
        v2Expanded: false,
        showDiff: false,
        diffIndex: this.actualSplitIndex,
        isV1: false,
        doTimeUpdate: true,
        bestTime: this.splitInfo[this.actualSplitIndex].bestTime ?? minigameConfig.dnfValue,
        isFinish: false
      })
      this.afterSplitTween?.kill()

    }

  }

  /**
   * nastavenie 3 branky pred cielom
   */
  public manageStateBeforeFinish(): void {

    if (modes.isTutorial() || modes.isTrainingMode()) return

    store.commit('TimeState/SET_SHOW_BOOLEANS', {
      v2Expanded: true,
      showDiff: false,
      diffIndex: this.splitCount,
      isV1: false,
      doTimeUpdate: true,
      bestTime: minigameConfig.dnfValue,
      isFinish: false
    })
    this.afterSplitTween?.kill()

  }

}
