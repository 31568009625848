export const checkPointsPlaceConfig = {
  TrackMarker1:
      {
        position: {
          x: 451.2381286621094,
          y: 505.78717041015625,
          z: -788.560546875
        },
        rotation: {
          _x: 0,
          _y: 0,
          _z: 0,
          _order: 'XYZ'
        }
      },
  TrackMarker2:
     {
       position: {
         x: 475.86431884765625,
         y: 450.19610595703125,
         // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
         z: -958.0466918945312
       },
       rotation: {
         _x: 0,
         _y: Math.PI / 8,
         _z: 0,
         _order: 'XYZ'
       }
     },
  TrackMarker3:
     {
       position: {
         x: 521.9318237304688,
         y: 405.81402587890625,
         z: -1070.2034912109375
       },
       rotation: {
         _x: 0,
         _y: Math.PI / 2,
         _z: 0,
         _order: 'XYZ'
       }
     },
  TrackMarker4:
    {
      position: {
        x: 515.4098510742188,
        y: 368.77294921875,
        z: -1187.124755859375
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker5:
    {
      position: {
        x: 538.3099975585938,
        y: 330.83856201171875,
        z: -1250.1915283203125
      },
      rotation: {
        _x: 0,
        _y: Math.PI / 8,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker6:
    {
      position: {
        x: 615.0416259765625,
        y: 301.3076171875,
        z: -1302.9681396484375
      },
      rotation: {
        _x: 0,
        _y: Math.PI / 8 + Math.PI / 2,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker7:
    {
      position: {
        x: 650.5103759765625,
        y: 273.60150146484375,
        z: -1393.8558349609375
      },
      rotation: {
        _x: 0,
        _y: Math.PI / 6,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker8:
    {
      position: {
        x: 487.32855224609375,
        // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
        y: 212.42044067382812,
        z: -1471.079345703125
      },
      rotation: {
        _x: 0,
        _y: Math.PI / 4,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker9:
     {
       position: {
         x: 472.2095031738281,
         y: 137.1697998046875,
         z: -1631.310546875
       },
       rotation: {
         _x: 0,
         _y: Math.PI / 8,
         _z: 0,
         _order: 'XYZ'
       }
     },
  TrackMarker10:
     {
       position: {
         x: 470.2611083984375,
         y: 70.55458068847656,
         z: -1744.0198974609375
       },
       rotation: {
         _x: 0,
         _y: -Math.PI / 8,
         _z: 0,
         _order: 'XYZ'
       }
     }
}
